import '../scss/foundation.scss';
import '../scss/main.scss';

// Either import everything
//import 'foundation-sites;


// Or import individual modules
/**/
import 'foundation-sites/dist/js/plugins/foundation.core';
import 'foundation-sites/dist/js/plugins/foundation.dropdownMenu.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.box.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.nest.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.imageLoader';
import 'foundation-sites/dist/js/plugins/foundation.util.motion';
import 'foundation-sites/dist/js/plugins/foundation.util.triggers';
import 'foundation-sites/dist/js/plugins/foundation.util.touch';
import 'foundation-sites/dist/js/plugins/foundation.equalizer';
import 'foundation-sites/dist/js/plugins/foundation.reveal';


import jQueryBridget from 'jquery-bridget';
import Masonry from 'masonry-layout';
import Accordions from './modules/Accordions';

document.addEventListener("DOMContentLoaded", function () {
	$(document).foundation();

	// mobile hamburger menu
	$('.hamburger').on('click', function (e) {
		$('.hamburger').toggleClass('is-active');
		$('.nav-menu-container').toggleClass('is-active');
		$('.nav-main').toggleClass('menu-active');
		$('.nav-bg').toggleClass('is-active');
		e.preventDefault();
	});

	// load more
	$('#loadmore').on('click', function (e) {
		e.preventDefault();
		$('.role-item:hidden').slice(0, 2).slideDown();
		if ($('.role-item:hidden').length == 0) {
			$('#loadmore').addClass('hide');
		}
	});

	// video modal open
	$('.modal-link').on('click', function () {
		$('#video-modal').foundation('open');
		$('.embed-player').attr("src", $(this).attr('data-video-link'));
		$('.embed-player').attr("title", $(this).attr('data-video-title'));
		$('.video-transcript .btn').attr("href", $(this).attr('data-video-transcript'));
	});

	// reveal video close and stop
	$('.reveal-overlay').on('click', function (e) {
		$('.reveal-video iframe').attr('src', '');
	});
	$('.reveal-video .close-button').on('click', function (e) {
		$('.reveal-video iframe').attr('src', '');
	});
	$('.reveal-video .video-transcript .btn-primary').on('click', function (e) {
		var tempLink = $('.reveal-video iframe').attr('src');
		var tempTitle = $('.reveal-video iframe').attr('title');
		$('.reveal-video iframe').attr('src', '');
		setTimeout(function(){ 
			$('.reveal-video iframe').attr('src', tempLink);
			$('.reveal-video iframe').attr('title', tempTitle);
		}, 500);
	});
	$(document).on('keyup', function (evt) {
		if (evt.keyCode == 27) {
			$('.reveal-video iframe').attr('src', '');
		}
	});

	// policy modal open
	$('.policy-modal-link').on('click', function () {
		$('#policy-modal').foundation('open');
		$('.btn-accept').attr("href", $(this).attr('data-apply-link'));
		$('.policy-modal-title').text($(this).attr('data-apply-title'));
	});

	// Close policy modal on apply
	$('.btn-accept').on('click', function () {
		$('#policy-modal').foundation('close');
	});

	// accordions
	const accordion = new Accordions();
	accordion.init();

	// masonry grid
	jQueryBridget('masonry', Masonry, $);
	$('.grid-masonry').masonry({
		itemSelector: '.grid-item',
		percentPosition: true,
		gutter: '.gutter-sizer',
		fitWidth: true
	});

});
